"use client";

import Button from '@mui/material/Button';
import { useSession, signIn, signOut } from "next-auth/react";
import { FunctionComponent } from "react";
import { useAuthContext } from 'src/auth/hooks';

import { paths } from 'src/routes/paths';


const DashboardButton: FunctionComponent = () => {
  const { login, logout } = useAuthContext();
  const { data: session } = useSession();
  let loggedIn = false;
  if(session && session.user){
    loggedIn = true;
  };

  return (
    <>
    <Button 
      variant="contained"  
      rel="noopener" 
      href="/dashboard"
      
    >
      Dashboard
    </Button>
  </>
  );
};

export default DashboardButton;

