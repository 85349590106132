// routes
import { paths } from 'src/routes/paths';
// config
import { PATH_AFTER_LOGIN } from 'src/config-global';
// components
import Iconify from 'src/components/iconify';
import * as React from 'react';
import { useSession } from 'next-auth/react';
// ----------------------------------------------------------------------

export const navConfig = [
  /* {
    title: 'Home',
    icon: <Iconify icon="solar:home-2-bold-duotone" />,
    path: '/',
  }, */
  // {
  //   title: 'Menu',
  //   path: '/pages',
  //   icon: <Iconify icon="solar:file-bold-duotone" />,
  //   children: [
  //     {
  //       subheader: 'Company',
  //       items: [
  //         { title: 'About us', path: paths.about },
  //         { title: 'Contact us', path: paths.contact },
  //         { title: 'FAQs', path: paths.faqs },
  //         { title: 'Pricing', path: paths.pricing },
  //       ],
  //     },
  //     {
  //       subheader: 'Dashboard',
  //       items: [{ title: 'Dashboard', path: PATH_AFTER_LOGIN }],
  //     },
  //   ],
  // },
  // {
  //   title: 'Docs',
  //   icon: <Iconify icon="solar:notebook-bold-duotone" />,
  //   path: paths.docs,
  // },
 /*  {
    title: 'Pricing',
    icon: <Iconify icon="solar:notebook-bold-duotone" />,
    path: paths.pricing
  }, */
];

